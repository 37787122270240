export default [
  { name: 'users', icon: 'mdi-account', routes: [ 'list' ] },
  {
    name: 'news',
    icon: 'mdi-newspaper-variant-multiple-outline',
    translatable: true
  },
  {
    name: 'categories',
    icon: 'mdi-ballot-outline',
    translatable: true
  },
  {
    name: 'departments',
    icon: 'mdi-office-building-outline',
    translatable: true
  },
  { name: 'specialties', icon: 'mdi-stethoscope', translatable: true },
  { name: 'doctors', icon: 'mdi-medical-bag', translatable: true },
  {
    name: 'pathologies',
    icon: 'mdi-emoticon-sick-outline',
    translatable: true
  },
  {
    name: 'testimonies',
    icon: 'mdi-account-heart-outline',
    translatable: true
  },
  { name: 'videos', icon: 'mdi-youtube', translatable: true },
  { name: 'events', icon: 'mdi-calendar', translatable: true },
  { name: 'medical_insurances', icon: 'mdi-shield-account' },
  {
    name: 'sliders',
    icon: 'mdi-image-multiple-outline',
    translatable: true
  },
  {
    name: 'about_incocegla',
    icon: 'mdi-image-multiple-outline',
    translatable: true
  },
  { name: 'general', icon: 'mdi-image-multiple-outline' },
  { name: 'installations', translatable: true },
  { name: 'clubs', translatable: true },
  { name: 'foundations', translatable: true  },
  { name: 'proyects' },
]
