<template>
    <va-layout>
        <va-app-bar
            slot="app-bar"
            :header-menu="headerMenu"
            :profile-menu="profileMenu"
            dense
            dark
            style="background: #37b353 !important"
            @toggle="
                $vuetify.breakpoint.lgAndUp
                    ? (mini = !mini)
                    : (drawer = !drawer)
            "
        ></va-app-bar>
        <va-sidebar
            slot="sidebar"
            :menu="sidebarMenu"
            v-model="drawer"
            :mini-variant="mini"
        ></va-sidebar>
        <template slot="header">
            <va-breadcrumbs></va-breadcrumbs>
            <impersonate-message></impersonate-message>
        </template>
        <va-aside slot="aside"></va-aside>
        <va-footer slot="footer" :menu="footerMenu">
            &copy; 2022
            by
            <a href="https://www.mixart.do">Mixart</a>
        </va-footer>
    </va-layout>
</template>

<script>
import ImpersonateMessage from "@/components/ImpersonateMessage";
import nav from "../_nav";

export default {
    name: "App",
    components: {
        ImpersonateMessage,
    },
    data() {
        return {
            drawer: null,
            mini: false,
            headerMenu: [
                {
                    link: "/",
                    text: this.$t("menu.dashboard"),
                },
            ],
            footerMenu: [
                {
                    //
                }
            ],
            profileMenu: [
                {
                    icon: "mdi-account",
                    text: this.$t("menu.profile"),
                    link: "/profile",
                },
            ],
            sidebarMenu: nav(this.$i18n, this.$admin),
        };
    },
};
</script>
