<template>
    <v-form ref="form" @submit.prevent="validate" class="mt-12">
        <v-text-field
            label="Correo"
            prepend-icon="mdi-account"
            v-model="form.username"
            required
            :error-messages="errorMessages.email"
        ></v-text-field>

        <v-text-field
            label="Contraseña"
            prepend-icon="mdi-lock"
            type="password"
            v-model="form.password"
            required
        ></v-text-field>

        <div class="text-center mt-4">
            <v-btn
                :loading="loading"
                color="primary"
                large
                type="submit"
                text
                rounded
                >Iniciar sessión</v-btn
            >
        </div>
    </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            form: {
                username: null,
                password: null,
                remember: false,
            },
            errorMessages: {},
            loading: false,
        };
    },
    methods: {
        ...mapActions({
            login: "auth/login",
        }),
        async validate() {
            if (this.$refs.form.validate()) {
                this.loading = true;

                try {
                    await this.login(this.form);
                } catch (e) {
                    if (e.errors) {
                        this.errorMessages = e.errors;
                        return;
                    }

                    this.errorMessages = { email: [e.message] };
                } finally {
                    this.loading = false;
                }
            }
        },
    },
};
</script>

<style scoped>
#login-page {
    background-color: var(--v-primary-lighten5);
}
</style>
