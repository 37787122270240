<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-boolean-input source="status"></va-boolean-input>
            <va-text-input source="title"></va-text-input>
            <va-rich-text-input source="description"></va-rich-text-input>
            <va-select-input
              item-text="name"
              :choices="positions"
              source="position"
            ></va-select-input>
            <va-select-input
              item-text="name"
              source="color"
              :choices="color"
            ></va-select-input>
            <va-file-input
              source="file"
              preview
              lg="4"
            >
            </va-file-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>

export default {
  props: ["id", "title", "item"],
  data: () => ({
    positions: [
      { name: 'Top left', value: 'justify-start align-start' },
      { name: 'Center left', value: 'justify-start align-center' },
      { name: 'Bottom left', value: 'justify-start align-end' },
      { name: 'Top center', value: 'justify-center align-start' },
      { name: 'Bottom center', value: 'justify-center align-end' },
      { name: 'Top right', value: 'justify-end align-start' },
      { name: 'Center right', value: 'justify-end align-center' },
      { name: 'Bottom right', value: 'justify-end align-end' },
      { name: 'Center center', value: 'justify-center align-center'}
    ],
    color: [
      { name: 'Azul', value: '0C3C56'},
      { name: 'Verde', value: '9DBA3B'},
      { name: 'Blanco', value: '9DBA3B'},
      { name: 'Negro', value: '9DBA3B'},
    ],
  }),
};
</script>
