/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/",
  },
  { divider: true },
  admin.getResourceLink("news"),
  admin.getResourceLink("categories"),
  admin.getResourceLink("departments"),
  admin.getResourceLink("specialties"),
  admin.getResourceLink("doctors"),
  admin.getResourceLink("pathologies"),
  admin.getResourceLink("testimonies"),
  admin.getResourceLink("videos"),
  admin.getResourceLink("events"),
  admin.getResourceLink("medical_insurances"),
  { divider: true },
  admin.getResourceLink("sliders"),
  admin.getResourceLink("about_incocegla"),
  admin.getResourceLink("general"),
  admin.getResourceLink("installations"),
  admin.getResourceLink("clubs"),
  admin.getResourceLink("foundations"),
  admin.getResourceLink("proyects"),
  admin.getResourceLink("users"),
];
