<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
              <va-text-input source="name"></va-text-input>
              <va-number-input source="age"></va-number-input>
              <va-select-input
                  source="pathology_id"
                  reference="pathologies"
                  label="Pathology"
                  item-text="name"
                  :itemsPerPage="null"
              ></va-select-input>
              <va-text-input source="message" multiline></va-text-input>
              <va-file-input
                  source="file"
                  preview
                  lg="4"
              >
              </va-file-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
