<template>
    <div></div>
</template>

<script>
export default {
    props: {
        token: String,
    },
    data() {
    },
};
</script>
