<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="name"></va-text-input>
            <va-select-input
              :itemsPerPage="null"
              item-text="name"
              label="Department"
              reference="departments"
              source="department_id"
            ></va-select-input>
            <va-select-input
              :itemsPerPage="null"
              item-text="name"
              label="Specialty"
              reference="specialties"
              source="specialty_id"
            ></va-select-input>
            <va-text-input source="description" multiline></va-text-input>
            <va-text-input source="address"></va-text-input>
            <va-text-input source="phone"></va-text-input>
            <va-text-input source="working_hours"></va-text-input>
            <va-file-input lg="4" preview source="file"></va-file-input>
          </v-card-text>
          <va-save-button></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
